// src/Dropzone.js
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Avatar } from "@mui/material";

function Dropzone({ onImageProcessed }) {
  const [image, setImage] = useState(null);
  const [webpImage, setWebpImage] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          const webpDataUrl = canvas.toDataURL("image/webp");
          setWebpImage(webpDataUrl);

          onImageProcessed(webpDataUrl); // Pass the WebP image data URL to parent
        };
      };

      reader.readAsDataURL(file);
      setImage(reader.result);
    },
    [onImageProcessed]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #ccc",
          borderRadius: 1,
          padding: 2,
          textAlign: "center",
          cursor: "pointer",
          transition: "border-color 0.3s ease",
          backgroundColor: isDragActive ? "#e0e0e0" : "#fff",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop the image here ...</Typography>
        ) : (
          <Typography>
            Drag 'n' drop an image here, or click to select one
          </Typography>
        )}
      </Box>
      {image && (
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Original Image:</Typography>
          <Avatar
            src={image}
            sx={{
              width: 100,
              height: 100,
              borderRadius: 1,
              margin: 1,
            }}
          />
          {webpImage && (
            <>
              <Typography variant="h6">WebP Image:</Typography>
              <Avatar
                src={webpImage}
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: 1,
                  margin: 1,
                }}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Dropzone;
