// src/App.js
import React, { useState } from "react";
import Dropzone from "./Dropzone";
import uploadToR2 from "./uploadToR2";
import { Container, Typography, Button } from "@mui/material";

function App() {
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);

  const handleImageProcessed = async (webpDataUrl) => {
    try {
      const imageUrl = await uploadToR2(webpDataUrl);
      setUploadedImageUrl(imageUrl);
    } catch (error) {
      console.error("Image upload failed:", error);
    }
  };

  return (
    <Container>
      <h1>
        React Drag-and-Drop Image Upload, Convert to WebP, and Upload to
        Cloudflare R2
      </h1>
      <Dropzone onImageProcessed={handleImageProcessed} />
      {uploadedImageUrl && (
        <div>
          <Typography variant="h6">Uploaded Image:</Typography>
          <Typography variant="h6">{uploadedImageUrl} </Typography>

          <img
            src={uploadedImageUrl}
            alt="Uploaded"
            style={{ maxWidth: "100%", height: "auto", marginTop: "20px" }}
          />
        </div>
      )}
    </Container>
  );
}

export default App;
