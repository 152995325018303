// src/uploadToR2.js
import AWS from "aws-sdk";
import { Buffer } from "buffer";
const uploadToR2 = async (webpDataUrl) => {
  const r2 = new AWS.S3({
    endpoint:
      "https://195a2d92ea5633159721d98872bcec19.r2.cloudflarestorage.com", // Replace with your R2 endpoint
    accessKeyId: "9114b1ca98000f769cf169d5a46424e3",
    secretAccessKey:
      "a6bbc19398eb2017a59770e1ced59b618deceecf1bd5a8a42c0bbbbdab4e829c",
    s3ForcePathStyle: true, // Needed for Cloudflare R2
  });

  const data = webpDataUrl.split(",")[1]; // Remove data URL scheme

  const params = {
    Bucket: "cheatsheet",
    Key: `images/image-${Date.now()}.webp`,
    Body: Buffer.from(data, "base64"),
    ContentEncoding: "base64",
    ContentType: "image/webp",
  };

  try {
    const response = await r2.upload(params).promise();
    console.log(response.Location);
    return `https://images.cheatsheet.cam/${params.Key}`; // URL of the uploaded image
  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
};

export default uploadToR2;
//  CORS settinf di Cloudlfare kena
// [
//   {
//     "AllowedOrigins": [
//       "http://localhost:3000"
//     ],
//     "AllowedMethods": [
//       "GET",
//       "POST",
//       "PUT"
//     ],
//     "AllowedHeaders": [
//       "*"
//     ],
//     "ExposeHeaders": [
//       "ETag"
//     ],
//     "MaxAgeSeconds": 3000
//   }
// ]
